































import {Component, Prop, Vue} from "vue-property-decorator";
import TextInput from "@/components/form/TextInput.vue";
import axios from "axios";
import PasswordInput from "../../components/form/PasswordInput.vue";
import FormButton from "@/components/form/Button.vue";
import {captureException} from "@sentry/browser";

@Component({
  components: {
    PasswordInput,
    TextInput,
    FormButton,
  },
  props: ["hash"],
})
export default class PasswordResetForm extends Vue {
  @Prop(String)
  public hash: string;

  public expiredOrInvalid = false;

  public password = "";
  public passwordWarning = false;
  public passwordConfirmation = "";
  public passwordConfirmationWarning = false;
  public passwordLengthWarning = false;

  public success = false;

  public request() {
    if (this.validate()) {
      axios.post("/auth/password-reset", {hash: this.hash, password: this.password})
        .then(() => {
          this.success = true;
        })
        .catch((e) => {
          if (e.response && e.response.status == 422) {
            this.expiredOrInvalid = true;
          } else {
            captureException(e);
            alert("Something went wrong");
          }
        });
    }
  }

  private validate() {
    this.passwordWarning = !this.password.trim() || this.password.length < 10;
    this.passwordLengthWarning = this.password.length < 10;
    this.passwordConfirmationWarning = !(this.password == this.passwordConfirmation);

    return !this.passwordWarning && !this.passwordLengthWarning && !this.passwordConfirmationWarning;
  }

}
